import { Api_Url } from "../constants/Url";

export const FetchAllProducts = async () => {
  try {
    const res = await fetch(`${Api_Url}/product/getallproduct`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the products",
    };
  }
};

export const GetSingleProductsApi = async (productId) => {
  try {
    const res = await fetch(
      `${Api_Url}/product/getsingleProduct/${productId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Products.",
    };
  }
};

export const AddToCart = async (data, token) => {
  try {
    const res = await fetch(`${Api_Url}/cart/createcart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const GetAllCart = async (data, token) => {
  console.log(data, token);
  try {
    const res = await fetch(`${Api_Url}/cart/getallcart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const RemoveCart = async (data, token) => {
  console.log(data, token, "responseData of remove cart");

  try {
    const res = await fetch(`${Api_Url}/cart/deletesinglecart`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    // // Parse the response as JSON
    const responseData = await res.json();

    console.log(responseData, "responseData of remove cart");

    // // Check if the request was successful
    // if (!res.ok) {
    //   throw new Error(
    //     responseData.message || "Failed to remove product from cart"
    //   );
    // }

    // // Return the response data (e.g., the updated cart or success message)
    // return responseData;
  } catch (error) {
    // Log the error for debugging
    console.error(
      "An error occurred while removing the product from the cart:",
      error.message
    );
    // Return error message to handle in the calling function
    return { success: false, message: error.message };
  }
};

export const UpdateAddToCart = async (data, token) => {
  try {
    const res = await fetch(`${Api_Url}/cart/updatesinglecart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    console.log(res, "UpdateAddToCart");

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const getAllProductsByCategory = async (data) => {
  try {
    const res = await fetch(
      `${Api_Url}/product/getallcategoryProduct/${data}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    console.log(res);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};


export const SearchProductApi = async (searchData) => {
  try {
    const res = await fetch(`${Api_Url}/product/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchData),
    });

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Products.",
    };
  }
};


