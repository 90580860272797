import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddToCart } from "../api/ProductsApi";
import { getFilteredProducts } from "../api/FilterProductsAPI";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Api_Url } from "../constants/Url";
import toast, { Toaster } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";

export const KitchenAppliances = () => {
  const navigate = useNavigate();

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const [ProductsData, SetProductsData] = useState([]);

  // Filter State
  const [priceMin, setPriceMin] = useState(1000); // Set initial min price
  const [priceMax, setPriceMax] = useState(100000); // Set initial max price
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [selectedShops, setSelectedShops] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const shops = ["Keel", "Grand Plus", "Royal AquaFresh", "Aqua Fresh"];
  const subCategories = ["Mixer Grinder", "Egg Boiler"];

  const fetchFilteredProducts = async () => {
    const filterData = {
      CategoryName: "Kitchen Appliances",
      SubCategoryName: selectedSubCategories.join(","),
      priceMin,
      priceMax,
      shopName: selectedShops.join(","),
      order: sortOrder,
    };

    try {
      const res = await getFilteredProducts(filterData);
      if (res?.status === "success") {
        SetProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShopChange = (shop) => {
    setSelectedShops((prev) =>
      prev.includes(shop) ? prev.filter((s) => s !== shop) : [...prev, shop]
    );
  };

  const handleSelectAllShops = (e) => {
    if (e.target.checked) {
      setSelectedShops(shops);
    } else {
      setSelectedShops([]);
    }
  };

  const handleSubCategoryChange = (subCategory) => {
    setSelectedSubCategories((prev) =>
      prev.includes(subCategory)
        ? prev.filter((s) => s !== subCategory)
        : [...prev, subCategory]
    );
  };

  const handleSelectAllSubCategories = (e) => {
    if (e.target.checked) {
      setSelectedSubCategories(subCategories);
    } else {
      setSelectedSubCategories([]);
    }
  };

  // Trigger API on filter changes
  useEffect(() => {
    fetchFilteredProducts();
  }, [priceMin, priceMax, selectedShops, sortOrder, selectedSubCategories]);

  // Handle Slider Change
  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    if (name === "min") {
      setPriceMin(parseInt(value));
    } else if (name === "max") {
      setPriceMax(parseInt(value));
    }
  };

  const HandleAddtoCart = async (productData) => {
    try {
      if (userId === undefined) {
        navigate("/login");
      }

      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(`productdetails/${productId}`);
  };

  return (
    <div className="container-fluid">
      <Toaster />

      {Array.isArray(ProductsData) && ProductsData?.length > 0 ? (
        <div>
          {/* Filters Section */}
          <div className="row my-4">
            <div className="col-md-3">
              <div>
                {/* Price Filter */}
                <div className="mb-3">
                  <h5 className="m-0">Filter by Price</h5>
                  <div className="price-slider-container">
                    <div className="price-values">
                      ₹ {priceMin} - ₹ {priceMax}
                    </div>
                    <input
                      type="range"
                      name="min"
                      min="1000"
                      max="100000"
                      value={priceMin}
                      onChange={handlePriceChange}
                      className="price-slider"
                    />
                    <input
                      type="range"
                      name="max"
                      min="1000"
                      max="100000"
                      value={priceMax}
                      onChange={handlePriceChange}
                      className="price-slider"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <h5 className="m-0">Filter by Price</h5>

                <select
                  className="form-select"
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  <option value="asc">Low to High</option>
                  <option value="desc">High to Low</option>
                </select>
              </div>

              <div className="mb-3">
                <h5 className="m-0">Filter by Subcategory</h5>
                <div className="subcategory-filters">
                  <input
                    type="checkbox"
                    onChange={handleSelectAllSubCategories}
                  />{" "}
                  All Subcategories
                  {subCategories.map((subCategory) => (
                    <div key={subCategory}>
                      <input
                        type="checkbox"
                        checked={selectedSubCategories.includes(subCategory)}
                        onChange={() => handleSubCategoryChange(subCategory)}
                      />{" "}
                      {subCategory}
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <h5 className="m-0">Filter by Shop Name</h5>
                <div className="shop-filters">
                  <input type="checkbox" onChange={handleSelectAllShops} /> All
                  Shops
                  {shops.map((shop) => (
                    <div key={shop}>
                      <input
                        type="checkbox"
                        checked={selectedShops.includes(shop)}
                        onChange={() => handleShopChange(shop)}
                      />{" "}
                      {shop}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div>
                <h4 className="m-0">Kitchen Appliances</h4>
                <p className="m-0">Total Products : {ProductsData?.length}</p>
              </div>
              <div className="row">
                {Array.isArray(ProductsData) && ProductsData.length > 0 ? (
                  <div className="row d-flex">
                    {ProductsData.map((ele, index) => (
                      <div
                        className="col-md-4 p-2"
                        key={ele?._id}
                        data-aos="fade-up"
                      >
                        <div
                          className="best_selling_product_main_div"
                          onClick={() => handleViewProduct(ele?._id)}
                        >
                          <div className="best_selling_img_box">
                            <img
                              src={`${Api_Url}${ele?.ProductImage?.[0]}`}
                              alt={ele?.ProductName}
                              className="img-fluid"
                            />
                          </div>

                          <div className="selling_products_name_div">
                            <h2>{ele?.ProductName}</h2>
                            <p>
                              <b>
                                {`₹ ${parseInt(
                                  ele?.priceAfterDiscount
                                ).toLocaleString()}`}{" "}
                                /-
                              </b>
                              <span className="ps-2">
                                {`₹ ${parseInt(
                                  ele?.totalPriceWithTax
                                ).toLocaleString()}`}{" "}
                                /-
                              </span>
                              <b className="ps-2 text-success">
                                Save {ele?.discountPercentage}%
                              </b>
                            </p>
                          </div>

                          <div className="product_Details">
                            <div className="view_Product_Details">
                              <div>View Product</div>
                            </div>

                            {/* Add to Cart button with stopPropagation */}
                            <div
                              className="add_to_cart bg-white"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent navigation
                                HandleAddtoCart(ele); // Calls the Add to Cart API
                              }}
                            >
                              <MdOutlineShoppingCart
                                style={{ fontSize: "20px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <h5 className="text-center">No Data Present</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};
