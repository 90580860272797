import { Api_Url } from "../constants/Url";

export const loginWithPhoneNumberApi = async (data) => {
  try {
    const res = await fetch(`${Api_Url}/user/sendotp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Ensure data is being passed correctly
    });
    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const otpVerifiedApi = async (data) => {
  try {
    const url = `${Api_Url}/user/login`;

    const requestData = {
      mobile: data.mobile,
      otp: data.otp,
    };

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const GetUserProfile = async (mobile, token) => {
  try {
    // Ensure mobile is provided
    if (!mobile) {
      throw new Error("Mobile number is required for fetching the profile");
    }

    const requestData = { mobile };

    const res = await fetch(`${Api_Url}/user/getuserprofile`, {
      method: "POST", // Use POST as your Node.js API expects
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
      body: JSON.stringify(requestData), // Send request body
    });

    const data = await res.json(); // Parse the response as JSON

    if (!res.ok) {
      throw new Error(data.message || "Failed to fetch user profile");
    }

    return data; // Return the user profile data
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message:
        error.message || "An error occurred while fetching the User Profile.",
    };
  }
};

export const RemoveAddress = async (mobile, addressId, token) => {
  try {
    if (!mobile || !addressId) {
      throw new Error(
        "Mobile number and address ID are required to remove the address"
      );
    }

    const requestData = { mobile, addressId };

    console.log(requestData, "requestData for address removal"); // Log the request data

    const res = await fetch(`${Api_Url}/user/update/deleteaddress`, {
      method: "DELETE", // Use POST as per your Node.js API
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
      body: JSON.stringify(requestData), // Send request body as a JSON string
    });

    const data = await res.json(); // Parse the response as JSON

    if (!res.ok) {
      throw new Error(data.message || "Failed to remove address");
    }

    return data; // Return the success message
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: error.message || "An error occurred while removing the address.",
    };
  }
};

export const AddNewAddress = async (formData, mobile) => {
  try {
    if (!mobile || !formData) {
      throw new Error(
        "Mobile number and formData are required to add the address"
      );
    }

    const requestData = {
      mobile, // mobile number
      address: formData, // address object containing address, pincode, state
    };

    console.log(requestData, "requestData for adding address"); // Log the request data

    const res = await fetch(`${Api_Url}/user/update/newaddress`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData), // Correctly format the body as JSON
    });

    // Handle non-JSON responses gracefully
    const contentType = res.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const data = await res.json(); // Parse the response as JSON
      if (!res.ok) {
        throw new Error(data.message || "Failed to add address");
      }
      return { status: "success", message: "Address added successfully!" };
    } else {
      const textResponse = await res.text(); // Handle non-JSON responses (e.g., HTML error pages)
      throw new Error(`Unexpected response: ${textResponse}`);
    }
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: error.message || "An error occurred while adding the address.",
    };
  }
};
export const UpdateUserProfile = async (formData) => {
  try {
    const response = await fetch(`${Api_Url}/user/updateuserprofile`, {
      method: "POST",
      body: formData,
    });

    console.log(response)

    // Check if response is OK
    if (!response.ok) {
      throw new Error("Failed to update profile");
    }

    return await response.json(); // Convert response to JSON
  } catch (error) {
    console.error("Error while updating profile:", error);
    throw error;
  }
};
