import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils";
import { FetchAllCart } from "../api/CartApi";
import Table from "react-bootstrap/esm/Table";
import { GetAllCart, RemoveCart, UpdateAddToCart } from "../api/ProductsApi";
import { IoTrashBinOutline } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { Api_Url } from "../constants/Url";

function QuantityInput() {
  const [quantity, setQuantity] = useState(1);

  const handleIncrease = () => {
    if (quantity <= 100) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="cart_qty_input">
      <button className="minus" aria-label="Decrease" onClick={handleDecrease}>
        −
      </button>
      <input
        type="number"
        className="input-box"
        value={quantity}
        onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
        min={1}
        max={10}
      />
      <button className="plus" aria-label="Increase" onClick={handleIncrease}>
        +
      </button>
    </div>
  );
}

export const Cart = () => {
  const [CartData, setCartData] = useState([]);

  const [quantity, setQuantity] = useState(1);

  const [particularItemQty, setparticularItemQty] = useState();

  const handleQuantityChange = async (productId, action) => {
    // Retrieve user data and token from cookies
    const userData = getDecryptedCookie("UserData");
    const token = userData?.token;
    const userId = userData?.userId;

    if (!token || !userId) {
      console.error("User is not authenticated");
      return;
    }

    // Update cart data in the frontend
    setCartData((prevCartData) =>
      prevCartData.map((item) => {
        if (item.product._id === productId) {
          let newQuantity = item.quantity;

          // Handle quantity increase or decrease logic
          if (action === "increase" && newQuantity < 10) {
            newQuantity += 1;
          } else if (action === "decrease" && newQuantity > 1) {
            newQuantity -= 1;
          }

          setparticularItemQty(newQuantity);

          // Return updated item with the new quantity
          return { ...item, quantity: newQuantity };
        }
        return item; // Return unmodified items
      })
    );

    // Prepare data for API call
    const data = {
      productId: productId,
      quantity: action === "increase" ? 1 : -1, // Calculate the new quantity to send to API
      userId: userId,
    };

    try {
      // Make API call to update the cart in the backend
      const UpdateApiResponse = await UpdateAddToCart(data, token);
      if (UpdateApiResponse?.status === "success") {
        toast.success(UpdateApiResponse?.message);
        FetchCartData();
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const handleIncrease = () => {
    if (quantity <= 100) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const FetchCartData = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const userId = userData?.userId;

      if (!userId || !token) {
        console.error("User ID or token is missing");
        return;
      }

      const data = { userId: userId }; // Dynamically use the user ID

      const res = await GetAllCart(data, token);

      if (res?.status === "success") {
        if (Array.isArray(res?.data)) {
          setCartData(res?.data); // Set cart data only if it's a valid array
        } else {
          setCartData([]); // Set an empty cart if no items are found
        }
      } else {
        console.error("Failed to fetch cart data", res?.message);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  useEffect(() => {
    FetchCartData();
  }, []);

  const handleDeleteCart = async (productData) => {
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token; // Extract token from user data
      const userId = userData?.userId; // Extract userId from user data

      if (!token || !userId) {
        throw new Error("User is not authenticated");
      }

      // Check the productData to ensure _id is present
      if (!productData || !productData._id) {
        throw new Error("Product data is missing or invalid");
      }

      const data = {
        productId: productData._id, // Use the product ID from the product data
        userId: userId, // Pass the user ID from the user data
      };

      const RemoveProductFromCart = await RemoveCart(data, token);
    } catch (error) {
      // Handle any errors during the deletion process
      console.error("Error deleting product from cart:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <div>
      <Toaster />

      <section className="inner_page_tisection_bred">
        <div className="container-fluid">
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  -
                  <li>
                    <Link to="/"> Cart</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/*Page Title Area End  */}

      {/*  */}
      <section className="cart_section">
        <div className="container">
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div>
                {CartData?.length > 0 ? (
                  <div className="cart_main_div_table">
                    <Table responsive="sm">
                      <thead>
                        <tr>
                          <th>S. no.</th>
                          <th>Product Name</th>
                          <th>Product Price</th>
                          <th>Product Image</th>
                          <th>Product Quantity</th>
                          <th>Total Price Quantity</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(CartData) && CartData?.length > 0 ? (
                          CartData.map((ele, index) => (
                            <tr key={ele?._id}>
                              <td>{index + 1}</td>
                              <td>{ele?.product?.ProductName}</td>
                              <td>
                                {`₹ ${parseInt(
                                  ele?.product?.ProductPrice
                                ).toLocaleString()}`}
                              </td>

                              <td>
                                <img
                                  src={
                                    ele?.product?.ProductImage?.[0]
                                      ? `${Api_Url}${ele.product.ProductImage[0]}`
                                      : "default-image-url.png"
                                  }
                                  alt={
                                    ele?.product?.ProductName || "Product Image"
                                  }
                                  className="img-fluid"
                                />
                              </td>
                              {/* <td>{ele?.quantity}</td> */}

                              <td>
                                <div className="d-flex align-items-center">
                                  <button
                                    onClick={() =>
                                      handleQuantityChange(
                                        ele?.product?._id,
                                        "decrease"
                                      )
                                    }
                                    className="btn btn-outline-secondary"
                                  >
                                    -
                                  </button>
                                  <span className="mx-2">{ele?.quantity}</span>
                                  <button
                                    onClick={() =>
                                      handleQuantityChange(
                                        ele?.product?._id,
                                        "increase"
                                      )
                                    }
                                    className="btn btn-outline-secondary"
                                  >
                                    +
                                  </button>
                                </div>
                              </td>

                              <td>
                                {`₹ ${parseInt(
                                  ele?.quantity * ele?.product?.ProductPrice
                                ).toLocaleString()}`}
                              </td>

                              <td>
                                <div className="d-flex">
                                  <div
                                    //   onClick={() => handleSingleTestimonial(ele)}
                                    className="action_button_view"
                                  >
                                    {/* <IoEye /> */}
                                  </div>
                                  <div
                                    className="px-2 action_button_update"
                                    //   onClick={() => handleUpdateTestimonial(ele)}
                                  >
                                    {/* <HiPencilSquare className="text-success" /> */}
                                  </div>
                                  <div
                                    className="action_button_delete"
                                    onClick={() => handleDeleteCart(ele)}
                                  >
                                    <IoTrashBinOutline className="text-danger" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              Data Not Available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <div className="checkout_btn_div mt-2">
                      <Link to="/checkout">Proceed To Checkout</Link>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row d-flex justify-content-center fs-1">
                      <div className="col-md-5 Section_Container">
                        <div className="text-center py-3">
                          <MdOutlineShoppingCart
                            className="text-danger"
                            style={{ fontSize: "100px" }}
                          />
                        </div>
                        <p className="text-center m-0">Your Cart is Empty</p>
                        <p className="text-center">
                          <a href="/" className="fs-5">
                            <span className="pe-1">Continue Shopping</span>
                            <FaArrowRightLong />
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
    </div>
  );
};
