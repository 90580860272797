import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Footer.css";
import logo from "../../assets/images/logo/logo2.png";
import fb from "../../assets/images/social-media-icons/fb.png";
import insta from "../../assets/images/social-media-icons/insta.png";

import { FaFacebookF, FaInstagram } from "react-icons/fa";
import FooterAccordion from "./FooterAccordion";

export const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row py-2 d-flex justify-content-between">
            <div className="col-md-3">
              <a href="/">
                <img
                  src={logo}
                  className="p-2 my-2"
                  alt=""
                  style={{ backgroundColor: "white" }}
                />
              </a>
              <div>
                <div>
                  <div>
                    <h5 className="m-0">Follow us:</h5>
                    <div className="d-flex">
                      <a
                        href="https://www.facebook.com/grandpluswaterpurifier"
                        target="#"
                      >
                        <img
                          src={fb}
                          alt=""
                          style={{ height: "42px", width: "40px" }}
                        ></img>
                      </a>

                      <a
                        href="https://www.instagram.com/grandpluswaterpurifier"
                        target="#"
                      >
                        <img
                          src={insta}
                          alt=""
                          style={{ height: "42px", width: "40px" }}
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>

                {/* <span className="pe-1 text-black">
                  Balaji Traders is a reputable company, our Regd. brand is
                  ''BALAJI TRADERS'' that specializes in providing high-quality
                  water purifiers. We offer a wide range of water purification
                  systems.
                </span> */}
                {/* <a
                  href="/aboutus"
                  style={{
                    color: "lightblue",
                    borderBottom: "1px solid lightblue", // Initial hidden underline
                  }}
                >
                  Read more...
                </a> */}
              </div>
            </div>

            <div className="col-md-8 desktop-view">
              <div className="row">
                <div className="col-md-5">
                  <div className="footer_heading">
                    <a href="/waterpurifier">
                      <h5>Products</h5>
                    </a>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <a href="/waterpurifier">
                          <i className="bx bx-droplet" /> Water Purifier
                        </a>
                      </li>
                      <li>
                        <a href="/waterdispensers">
                          <i className="bx bx-droplet" /> Water Dispensers
                        </a>
                      </li>

                      <li>
                        <a href="/kitchenappliances">
                          <i className="bx bx-droplet" /> Kitchen Appliances
                          (Launching Soon)
                        </a>
                      </li>
                      <li>
                        <a href="/vacuumcleaner">
                          <i className="bx bx-droplet" /> Vacuum Cleaner
                          (Launching Soon)
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="footer_heading">
                    <h5>Our Brands</h5>
                    <ul>
                      <li>
                        <a href="/https://aquafreshwaterpurifiers.in/">
                          <i className="bx bx-droplet" /> Aquafresh
                        </a>
                      </li>
                      <li>
                        <a href="/https://grandpluswaterpurifier.com/">
                          <i className="bx bx-droplet" /> Grand Plus
                        </a>
                      </li>
                      <li>
                        <a href="/https://royalaquafreshwaterpurifier.com/">
                          <i className="bx bx-droplet" /> Royal Aquafresh
                        </a>
                      </li>

                      <li>
                        <a href="/https://keelwaterpurifier.com/">
                          <i className="bx bx-droplet" /> Keel
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="footer_heading">
                    <h5>Useful Links</h5>
                    <ul>
                      {/* <li>
                        <a href="/aboutus">
                          <i className="bx bx-droplet" /> About Us
                        </a>
                      </li>

                      <li>
                        <a href="/contactus">
                          <i className="bx bx-droplet" /> Contact Us
                        </a>
                      </li> */}
                      <li>
                        <a href="/termsconditions">
                          <i className="bx bx-droplet" /> Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a href="/returnrefundpolicy">
                          <i className="bx bx-droplet" /> Return & Refund Policy
                        </a>
                      </li>
                      <li>
                        <a href="/privacypolicy">
                          <i className="bx bx-droplet" /> Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="/disclaimer">
                          <i className="bx bx-droplet" /> Disclaimer
                        </a>
                      </li>
                      <li>
                        <a href="/sitemap">
                          <i className="bx bx-droplet" /> Sitemap
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mobile-view">
              <FooterAccordion />
            </div>
          </div>
        </div>
      </footer>

      {/* <footer className="bg-success">
        <div className="footer_overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                <div className="footer_headti">
                  <h5>Company</h5>
                  <ul>
                    <li>
                      <a href="/">
                        <i className="bx bx-droplet" /> Home
                      </a>
                    </li>
                    <li>
                      <a href="/aboutus">
                        <i className="bx bx-droplet" /> About Us
                      </a>
                    </li>

                    <li>
                      <a href="/contactus">
                        <i className="bx bx-droplet" /> Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footer_headti">
                  <h5>Products</h5>
                  <ul>
                    <li>
                      <a href="/waterpurifier">
                        <i className="bx bx-droplet" /> Water Purifier
                      </a>
                    </li>
                    <li>
                      <a href="/waterdispensers">
                        <i className="bx bx-droplet" /> Water Dispensers
                      </a>
                    </li>

                    <li>
                      <a href="/kitchenappliances">
                        <i className="bx bx-droplet" /> Kitchen Appliances
                        (Launching Soon)
                      </a>
                    </li>
                    <li>
                      <a href="/vacuumcleaner">
                        <i className="bx bx-droplet" /> Vacuum Cleaner
                        (Launching Soon)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="footer_headti">
                  <h5>Our Brands</h5>
                  <ul>
                    <li>
                      <a href="/https://aquafreshwaterpurifiers.in/">
                        <i className="bx bx-droplet" /> Aquafresh
                      </a>
                    </li>
                    <li>
                      <a href="/https://grandpluswaterpurifier.com/">
                        <i className="bx bx-droplet" /> Grand Plus
                      </a>
                    </li>
                    <li>
                      <a href="/https://royalaquafreshwaterpurifier.com/">
                        <i className="bx bx-droplet" /> Royal Aquafresh
                      </a>
                    </li>

                    <li>
                      <a href="/https://keelwaterpurifier.com/">
                        <i className="bx bx-droplet" /> Keel
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                <div className="footer_headti">
                  <h5>Useful Links</h5>
                  <ul>
                    <li>
                      <a href="/termsconditions">
                        <i className="bx bx-droplet" /> Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a href="/returnrefundpolicy">
                        <i className="bx bx-droplet" /> Return & Refund Policy
                      </a>
                    </li>
                    <li>
                      <a href="/privacypolicy">
                        <i className="bx bx-droplet" /> Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="/disclaimer">
                        <i className="bx bx-droplet" /> Disclaimer
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}

      <section className="copyright_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="m-0 text-center">
                Copyright © 2024 Balaji Water Purifier. All Right Reserved.
              </p>
            </div>

            {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <p className="copyright_icons">
                <Link to="/sitemap">Sitemap</Link>
                <Link to="">FAQs</Link>
                <span>
                  <a
                    href="https://www.instagram.com/grandpluswaterpurifier"
                    target="#"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.facebook.com/grandpluswaterpurifier"
                    target="#"
                  >
                    <FaFacebookF />
                  </a>
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
