import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../styles/ProductDetails.css";
import Slider from "react-slick";
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";
import { AddToCart, GetSingleProductsApi } from "../api/ProductsApi";
import { Loader } from "../components/Loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Helmet } from "react-helmet-async";
import { Api_Url } from "../constants/Url";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GiElectric } from "react-icons/gi";
import toast, { Toaster } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";

export const ProductDetails = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  const handleImageClick = (image) => {
    setSelectedImage(`${Api_Url}${image}`);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  const { productId } = useParams();
  const [productdetails, setProductDetails] = useState(null); // Initialize as null to check loading state
  const [quantity, setQuantity] = useState(1);

  // Format the date and time
  const formatDateTime = () => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };
    const date = new Date().toLocaleDateString("en-US", options);
    const time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${date} ${time}`;
  };

  // User Data
  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  // Fetch product details by ID
  const getSingleProduct = async () => {
    try {
      const res = await GetSingleProductsApi(productId);
      console.log("GetSingleProductsApi", res);
      if (res?.status === "success") {
        setProductDetails(res?.data);
        setSelectedImage(`${Api_Url}${res.data.ProductImage[0]}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, [productId]);

  // Generate unique order ID
  const generateUniqueOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [orderId, setOrderId] = useState(generateUniqueOrderId());

  useEffect(() => {
    generateUniqueOrderId();
    console.log(orderId, "OrderId");
  }, []);

  // Generate PDF
  const generatePdf = () => {
    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.text("Balaji Traders", 14, 20);
    doc.setFontSize(10);
    doc.text(`Invoice Number: ${orderId}`, 14, 30);
    doc.text(`Date: ${formatDateTime()}`, 14, 35);

    // Bill from
    doc.text("Bill from:", 14, 45);
    doc.text("Company Name", 14, 50);
    doc.text("Street Address, Zip Code", 14, 55);
    doc.text("Phone Number", 14, 60);

    // Bill to (dummy data for now)
    doc.text("Bill to:", 14, 70);
    doc.text("Customer Name", 14, 75);
    doc.text("Street Address, Zip Code", 14, 80);
    doc.text("Phone Number", 14, 85);

    // Table setup
    const tableColumn = ["Item", "Quantity", "Rate", "Tax", "Amount"];
    const tableData = [
      [
        productdetails?.ProductName,
        quantity,
        productdetails?.ProductPrice,
        "0.00",
        `Rs. ${totalPrice.toLocaleString()}`,
      ],
    ];

    doc.autoTable({
      head: [tableColumn],
      body: tableData,
      startY: 95,
      theme: "grid",
    });

    // Summary
    doc.text(
      `Subtotal: Rs. ${totalPrice.toLocaleString()}`,
      14,
      doc.autoTable.previous.finalY + 20
    );
    doc.text("Discount: Rs. 0.00", 14, doc.autoTable.previous.finalY + 25);
    doc.text("Tax: Rs. 0.00", 14, doc.autoTable.previous.finalY + 30);
    doc.text("Paid: Rs. 0.00", 14, doc.autoTable.previous.finalY + 35);
    doc.text(`Total: Rs ${totalPrice}`, 14, doc.autoTable.previous.finalY + 40);

    // Save the PDF
    doc.save("invoice.pdf");
  };

  if (!productdetails) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    ); // Render loading state until data is available
  }

  // Handle quantity increase or decrease
  const handleQuantityChange = (action) => {
    if (action === "increase") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (action === "decrease" && quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const totalPrice = productdetails?.ProductPrice * quantity;

  const HandleAddtoCart = async () => {
    try {
      if (userId === undefined) {
        navigate("/login");
      }

      const data = {
        productId: productId,
        quantity: quantity,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster />
      <Helmet>
        <title>{productdetails?.ProductName || "Balaji Traders"}</title>
        <meta name="description" content={productdetails?.ProductDescription} />
        <meta property="og:title" content={productdetails?.ProductName} />
        <meta
          property="og:description"
          content={productdetails?.ProductDescription}
        />
        <meta
          property="og:image"
          content={`https://api.balajiwaterpurifier.com${productdetails?.ProductImage[0]}`}
        />
        <meta
          property="og:url"
          content={`https://balajiwaterpurifier.com/productdetails/${productId}`}
        />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={productdetails?.ProductName} />
        <meta
          property="twitter:description"
          content={productdetails?.ProductDescription}
        />
        <meta
          property="twitter:image"
          content={`${Api_Url}${productdetails?.ProductImage[0]}`}
        />
      </Helmet>

      <section className="products_details_section">
        <div className="container">
          <div className="row">
            {/* Product Image Gallery */}
            <div className="col-lg-4 col-md-5">
              {/* Main Product Image with Zoom on Hover */}
              <div
                className="main-image"
                onMouseMove={handleMouseMove}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                style={{ border: "1px solid #ddd" }}
              >
                <img
                  src={selectedImage}
                  alt="Selected Product"
                  className="zoom-image"
                />
              </div>

              <div className="image-list">
                {productdetails?.ProductImage.map((image, index) => (
                  <img
                    key={index}
                    src={`${Api_Url}${image}`}
                    alt={`Product ${index}`}
                    className={`thumbnail  ${
                      selectedImage === `${Api_Url}${image}` ? "active" : ""
                    }`}
                    onClick={() => handleImageClick(image)}
                  />
                ))}
              </div>
            </div>

            {/* Zoom Preview on the Right Side */}
            {isHovering && (
              <div
                className="zoom-preview-container bg-white"
                style={{
                  position: "fixed",
                  top: "15%",
                  right: "5%",
                  width: "500px",
                  height: "400px",
                  overflow: "hidden",
                  zIndex: 10,
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <img
                  src={selectedImage}
                  alt="Zoomed Product"
                  style={{
                    transform: `translate(-${zoomPosition.x}%, -${zoomPosition.y}%) scale(1.1)`,
                    width: "100%",
                  }}
                />
              </div>
            )}

            {/* Product Details */}
            <div className="col-lg-8 col-md-7">
              <div className="products_dt_txt_div">
                <h2>{productdetails?.ProductName}</h2>

                {/* Product Rating */}
                <div className="review-box">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star-half-o"></i>
                  <span>
                    ({productdetails?.ProductRatingAndReviews?.averageRating}{" "}
                    Rating)
                  </span>
                </div>

                {/* Product description */}
                <p className="m-0">{productdetails?.ProductDescription}</p>

                {/* Product features */}
                {productdetails?.ProductFeatures && (
                  <ul>
                    {productdetails.ProductFeatures.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                )}

                <p className="products_dt_price">
                  <b className="productsnetprice" style={{ fontSize: "20px" }}>
                    ₹{" "}
                    {parseInt(
                      productdetails?.priceAfterDiscount
                    ).toLocaleString()}{" "}
                    /-
                  </b>
                  <span className="productsdissprice px-2">
                    ₹{" "}
                    {parseInt(
                      productdetails?.totalPriceWithTax
                    ).toLocaleString()}{" "}
                  </span>
                  <span className="text-success">
                    Save {productdetails?.discountPercentage}%
                  </span>
                </p>
                <h6 className="products_dt_disscount">
                  <span className="products_dtfreedlv">Free Delivery</span>
                </h6>

                {/* Quantity Selector */}
                <div className="quantity_selector">
                  <h5>Quantity</h5>
                  <div className="d-flex align-items-center">
                    <button
                      onClick={() => handleQuantityChange("decrease")}
                      className="PlusMinusButtons"
                    >
                      <CiCircleMinus className="fs-3" />
                    </button>
                    <span className="PriceDiv">{quantity}</span>
                    <button
                      onClick={() => handleQuantityChange("increase")}
                      className="PlusMinusButtons"
                    >
                      <CiCirclePlus className="fs-3" />
                    </button>
                  </div>
                </div>

                {/* Total Price */}
                <p className="products_dt_price py-1">
                  ₹ {totalPrice.toLocaleString()} /-
                </p>

                {/* Checkout Button */}
                <div className="d-flex">
                  <button className="add_cart_btn" onClick={HandleAddtoCart}>
                    <span className="pe-1">Add to Cart</span>
                    <MdOutlineShoppingCart />
                  </button>

                  <Link to="checkout" className="ps-2">
                    <button className="buy_now_btn">
                      <span className="pe-1">Buy Now</span>
                      <GiElectric />
                    </button>
                  </Link>

                  {/* Generate Invoice Button */}
                  <button
                    onClick={generatePdf}
                    style={{
                      padding: "5px 15px",
                      borderRadius: "5px",
                      border: "1px solid blue",
                    }}
                  >
                    Generate Invoice
                  </button>
                </div>
              </div>
            </div>


            
          </div>
        </div>
      </section>
    </>
  );
};
