import React from "react";
import { Navigate } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils";

// This component checks if the user is logged in
export const PrivateRoute = ({ children }) => {
  const userData = getDecryptedCookie("UserData"); 
  const isAuthenticated = userData?.token;

  return isAuthenticated ? children : <Navigate to="/login" />;
};
