import React from "react";
import "../styles/ContactUs.css";
import { Link } from "react-router-dom";

export const ContactUs = () => {
  return (
    <>
      <section className="inner_page_tisection_bred">
        <div className="container-fluid">
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  -
                  <li>
                    <Link to="/"> Company Profile</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/*Page Title Area End  */}

      {/* ===================contact us page section start ============== */}
      <section className="contact_us_page_section">
        <div className="container">
          <div className="row">
            {/* col start  */}
            <div className="col-lg-5 col-md-6 col-sm-12 col-12">
              {/* main div contact page start*/}
              <div className="main_div_contactus_page">
                {/* contact us flex div main start*/}
                <div className="contact_us_flx_div_main">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="bx bxs-phone-call" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="contact_page_num_div">
                        <b>Mobile No.:</b>
                        <a href="tel:+919810392397"> +91-9810392397</a>
                        <a href="tel:+918010804949"> +91-8010804949</a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* contact us flex div main end*/}
                {/* contact us flex div main start*/}
                <div className="contact_us_flx_div_main">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="bx bx-mail-send" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="contact_page_num_div">
                        <b>E-Mail ID:</b>
                        <a href="mailto:helpdesk@aquafreshwaterpurifiers.in">
                          helpdesk@aquafreshwaterpurifiers.in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* contact us flex div main end*/}
                {/* contact us flex div main start*/}
                <div className="contact_us_flx_div_main">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="bx bxs-map-pin" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="contact_page_num_div">
                        <b>Address:</b>
                        <p>
                          Plot No - 70, Vikash Vihar Near SS Property, Rohini
                          Sec - 22, Delhi - 110086 (INDIA)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* contact us flex div main end*/}
              </div>
              {/* main div contact page end*/}
            </div>
            {/* col end */}
            {/* col start */}
            <div className="col-lg-7 col-md-6 col-sm-12 col-12">
              <div className="contact_page_form">
                <h2>Enquiry Now</h2>
                <form
                  method="POST"
                  className="contact-form"
                  autoComplete="off"
                  onsubmit="return submitUserForm();"
                >
                  {/* row nested start */}
                  <div className="row">
                    {/* col nested start */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text fa fa-user" />
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name*"
                          required
                          minLength={3}
                          oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '').replace(/(\..*?)\..*/g, '$1')"
                        />
                      </div>
                    </div>
                    {/* col nested end */}
                    {/* col nested start */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text fa fa-user" />
                        <input
                          type="text"
                          name="last"
                          className="form-control"
                          placeholder="Surname*"
                          required
                          minLength={3}
                          oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '').replace(/(\..*?)\..*/g, '$1')"
                        />
                      </div>
                    </div>
                    {/* col nested end */}
                    {/* col nested start */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text fa fa-envelope" />
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email Id*"
                          minLength={11}
                          required
                        />
                      </div>
                    </div>
                    {/* col nested end */}
                    {/* col nested start */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text fa fa-phone" />
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          id="phone"
                          placeholder="Mobile No."
                          required
                        />
                      </div>
                      <div id="phoneval22" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text fa fa-commenting" />
                        <textarea
                          name="message"
                          className="form-control"
                          placeholder="Write Your Requirement*"
                        />
                      </div>
                      <div id="warden_id" />
                    </div>
                    {/* col nested end */}
                    <div
                      className="g-recaptcha "
                      data-sitekey="6LdXvXMpAAAAAPr-OXmelKI5WWojKH17ukTBQUDn"
                      data-callback="verifyCaptcha"
                    ></div>
                    <div id="g-recaptcha-error" />
                    {/* col nested start */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                      <div className="input_div">
                        <div className="form-group">
                          <center>
                            <input
                              type="submit"
                              name="SubmitEmail"
                              Value="Submit Request"
                              className="btn"
                            />
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* col nested end */}
                </form>
              </div>
              {/* row nested end */}
            </div>
          </div>
          {/* col end */}
          <br />
          <div className="col-lg-12">
            <p>
              <iframe
                title="google map location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55993.924138951596!2d77.054041!3d28.701004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d042d24bc6855%3A0xf0de01bc5d6902c4!2sAquafresh%20RO%20Customer%20Care%20Number%20-%20Aquafresh%20RO%20Complaint%20Number!5e0!3m2!1sen!2sin!4v1723563961675!5m2!1sen!2sin"
                width="100%"
                height="350"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
