import { Api_Url } from "../constants/Url";

export const getFilteredProducts = async (data) => {
    try {
        const res = await fetch(`${Api_Url}/product/getAllFilteredProduct?CategoryName=${data?.CategoryName}&SubCategoryName=${data?.SubCategoryName}&priceMin=${data?.priceMin}&priceMax=${data?.priceMax}&shopName=${data?.shopName}&sortBy=price&order=${data?.order}`, {
            method: "Get",
            headers: {
                "Content-type": "application/json",
            },
        });
        return await res.json();
    } catch (error) {
        console.log(error);
    }
};