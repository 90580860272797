import React, { useEffect, useState } from "react";
import { Api_Url } from "../../constants/Url";
import { FetchAllCatgories } from "../../api/CategoryApi";
import { useNavigate } from "react-router-dom";
import waterpurifiers from "../../assets/images/shop-by-category/waterpurifiers.webp";
import waterdispensers from "../../assets/images/shop-by-category/waterdispensers.webp";
import kitchenappliances from "../../assets/images/shop-by-category/kitchenappliances.webp";
import vacuumcleaners from "../../assets/images/shop-by-category/vacuumcleaners.webp";
import "../../styles/ShopByCategory.css";

export const ShopByCategory = () => {
  const [AllTestimonialsData, setAllTestimonialsData] = useState();
  const navigate = useNavigate();

  const AllTestimonialsFetch = async () => {
    try {
      const res = await FetchAllCatgories();
      if (res?.status === "success") {
        setAllTestimonialsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllTestimonialsFetch();
  }, []);

  // Handle navigation when clicking on a card
  const handleCardClick = (categoryName) => {
    const formattedCategoryName = categoryName
      .replace(/\s+/g, "")
      .toLowerCase();
    navigate(`/${formattedCategoryName}`);
  };

  return (
    <div>
      <div>
        <section className="testimonial_section shop_by_category_div">
          <div className="container">
            <div className="testimonails_title">
              <h2>Shop By Category</h2>
            </div>

            <div className="row">
              <div className="col-md-6  p-2" style={{ paddingRight: "10px" }}>
                <a href="/waterdispensers">
                  <img
                    src={waterpurifiers}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </a>
              </div>
              <div className="col-md-6 p-0   p-2">
                <div className="row">
                  <div className="col-md-12 px-2">
                    <a href="/waterdispensers">
                      <img
                        src={waterdispensers}
                        alt=""
                        style={{ width: "100%", marginBottom: "10px" }}
                      />
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-md-6 col-6 px-2"
                    style={{ paddingRight: "5px" }}
                  >
                    <a href="/waterdispensers">
                      <img
                        src={kitchenappliances}
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6 col-6 px-2" style={{ paddingLeft: "5px" }}>
                    <a href="/waterdispensers">
                      <img
                        src={vacuumcleaners}
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
