import React, { Suspense, lazy } from "react";
import { Loader } from "../components/Loader";
import { FAQ } from "../components/homePageComponents/FAQ";

// import { Testimonials } from "../components/homePageComponents/Testimonials";
// import { OurBrands } from "../components/homePageComponents/OurBrands";
// import { OtherWayToBuy } from "../components/homePageComponents/OtherWayToBuy";
// import { BestSellingPurifiers } from "../components/homePageComponents/BestSellingPurifiers";
import { Banners } from "../components/homePageComponents/Banners";
import { AdsBanner } from "./AdsBanner";
import { ShopByCategory } from "../components/homePageComponents/ShopByCategory";
import { LatestBlogs } from "../components/homePageComponents/LatestBlogs";
import NewArrivals from "../components/homePageComponents/NewArrivals";
import { HomeWaterPurifiers } from "../components/homePageComponents/HomeWaterPurifiers";
import { HomeWaterDispensers } from "../components/homePageComponents/HomeWaterDispensers";

const Testimonials = lazy(() =>
  import("../components/homePageComponents/Testimonials")
);
const OurBrands = lazy(() =>
  import("../components/homePageComponents/OurBrands")
);

const OtherWayToBuy = lazy(() =>
  import("../components/homePageComponents/OtherWayToBuy")
);

// const Banners = lazy(() => import("../components/homePageComponents/Banners"));
const BestSellingPurifiers = lazy(() =>
  import("../components/homePageComponents/BestSellingPurifiers")
);

export const DashBoard = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Banners />
      <BestSellingPurifiers />
      <ShopByCategory />
      <HomeWaterPurifiers />
      <HomeWaterDispensers />
      <AdsBanner />
      <OurBrands />
      <AdsBanner />
      <NewArrivals />
      <OtherWayToBuy />
      {/* <Testimonials /> */}
      <LatestBlogs />
      {/* <FAQ /> */}
    </Suspense>
  );
};
