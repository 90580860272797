import React from "react";
import { Link } from "react-router-dom";
import "../styles/Disclaimer.css";

export const TermsConditions = () => {
  return (
    <div>
      <section className="inner_page_tisection_bred">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  -
                  <li>
                    <Link to="/"> Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="termsconditions_section">
        <div className="container">
          <div className="termsconditions_title_div">
            <h1>Terms & Conditions</h1>
            <p><strong>Last updated: September 28, 2024</strong></p>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="termsconditions_txt_div">
              <h5>Introduction</h5>
              <p>
                Please read these terms and conditions carefully before using Our Service.
              </p>

              <h5>Interpretation and Definitions</h5>

              <h6>Interpretation</h6>
              <p>
                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
              </p>

              <h6>Definitions</h6>
              <p>For the purposes of these Terms and Conditions:</p>
              <ul>
                <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party.</li>
                <li><strong>Country</strong> refers to: Delhi, India.</li>
                <li><strong>Company</strong> refers to Balaji Traders.</li>
                <li><strong>Device</strong> means any device that can access the Service.</li>
                <li><strong>Service</strong> refers to the Website.</li>
                <li><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions.</li>
                <li><strong>Website</strong> refers to Balaji Traders, accessible from <a href="https://www.balajiwaterpurifier.com/">https://www.balajiwaterpurifier.com/</a></li>
                <li><strong>You</strong> means the individual or legal entity using the Service.</li>
              </ul>

              <h5>Acknowledgment</h5>
              <p>
                These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. 
                Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions.
              </p>

              <h5>Links to Other Websites</h5>
              <p>
                Our Service may contain links to third-party websites or services that are not owned or controlled by the Company. 
                The Company assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services.
              </p>

              <h5>Termination</h5>
              <p>
                We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including breach of these Terms.
              </p>

              <h5>Limitation of Liability</h5>
              <p>
                To the maximum extent permitted by applicable law, the Company shall not be liable for any indirect, incidental, or consequential damages.
              </p>

              <h5>"AS IS" and "AS AVAILABLE" Disclaimer</h5>
              <p>
                The Service is provided "AS IS" and "AS AVAILABLE" without warranty of any kind. The Company disclaims all warranties related to the Service.
              </p>

              <h5>Governing Law</h5>
              <p>
                The laws of the Country, excluding its conflict of law rules, shall govern these Terms.
              </p>

              <h5>Disputes Resolution</h5>
              <p>
                If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
              </p>

              <h5>Severability and Waiver</h5>

              <h6>Severability</h6>
              <p>
                If any provision of these Terms is held to be unenforceable or invalid, the remaining provisions will continue in full force and effect.
              </p>

              <h6>Waiver</h6>
              <p>
                The failure to enforce any right under these Terms shall not be a waiver of future enforcement.
              </p>

              <h5>Changes to These Terms and Conditions</h5>
              <p>
                We reserve the right to modify or replace these Terms at any time.
              </p>

              <h5>Contact Us</h5>
              <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
              <ul>
                <li>By email: helpdesk@balajiwaterpurifier.com</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
