import React, { useEffect, useState } from "react";
import { getDecryptedCookie } from "../utils/Utils";
import { GetUserProfile } from "../api/UsersApi";
import "../styles/UserProfile.css";
import { Link } from "react-router-dom";

export const UserProfile = () => {
  const [userData, setUserData] = useState(null);

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const mobile = userData?.mobile;

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      const res = await GetUserProfile(mobile, token);

      if (res?.status === "success") {
        setUserData(res.data);
      } else {
        console.error("Failed to fetch user profile", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserProfile();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-content-center pt-5">
          <div className="col-lg-8 col-md-7 col-sm-12 col-12">
            <div className="userProfile">
              <div className="p-3" style={{ border: "1px solid gray" }}>
                <div className="userProfile d-flex justify-content-between">
                  <h5>User Data</h5>
                  <div>
                    <Link to="/editprofile">
                      <button>Edit Profile</button>
                    </Link>
                  </div>
                </div>
                <div className="userProfileRow">
                  <span className="field">Name :</span>
                  <span className="value">{userData?.name}</span>
                </div>
                <div className="userProfileRow">
                  <span className="field">Mobile :</span>
                  <span className="value">{userData?.mobile}</span>
                </div>
              </div>

              <div className="p-3" style={{ border: "1px solid gray" }}>
                <div className="userProfile d-flex justify-content-between">
                  <h5>Addresses Data</h5>
                  <div>
                    <Link to="/editaddress">
                      <button>View All Address</button>
                    </Link>
                  </div>
                </div>

                <div>
                  {userData?.addresses?.length > 0 ? (
                    <div>
                      {userData?.addresses?.map((ele, index) => {
                        return (
                          <div key={index} className="userProfileRow d-flex">
                            <div className="field">Address {index + 1} :</div>
                            <div className="value">
                              {ele?.address}, {ele?.pincode}, {ele?.state}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>No Addresses is Added Yet!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
