import React, { useEffect, useState } from "react";
import "../styles/EditProfile.css";
import { IoCamera } from "react-icons/io5";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { GetUserProfile, UpdateUserProfile } from "../api/UsersApi";
import { getDecryptedCookie } from "../utils/Utils";
import { Api_Url } from "../constants/Url";
import { LuUser2 } from "react-icons/lu";
// import user from "../assets/images/icon/user.png";

export const EditProfile = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [profilePreview, setProfilePreview] = useState(null);
  const [userData, setUserData] = useState(null);

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile; // Use mobile instead of userId

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      // Call GetUserProfile API with mobile and token
      const res = await GetUserProfile(mobile, token);
      console.log(res);
      if (res?.status === "success") {
        setUserData(res?.data);
        setProfilePreview(res?.data?.ProfilePhoto ? `${Api_Url}${res.data.ProfilePhoto}` : null);
        // setProfilePreview(res?.data?.ProfilePhoto);

        // http://localhost:9090/image/IMG1729073561678-575416836.jpeg
      } else {
        console.error("Failed to fetch user user addresses", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserProfile();
  }, []);

  // Update profilePreview based on userData.ProfilePhoto
  useEffect(() => {
    if (userData?.ProfilePhoto) {
      setProfilePreview(`${Api_Url}${userData.ProfilePhoto}`);
    }
  }, [userData]);

  // Edit Profile

  const editChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      console.log(profileImage);
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const initialValues = {
    name: userData?.name || "",
    email: userData?.email || "",
    mobile: userData?.mobile || "",
    ProfilePhoto: null, // For image
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Mobile is required"),
    ProfilePhoto: Yup.mixed().nullable(),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      const formData = new FormData();

      // Append form data
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("mobile", values.mobile);
      if (profileImage) {
        formData.append("ProfilePhoto", profileImage); // Append the image file
      }

      // Log form values and image file
      console.log("Form values:", values);
      console.log("Image file:", profileImage); // Log the image

      const res = await UpdateUserProfile(formData);
      if (res?.status === "success") {
        toast.success("Form submitted successfully!");
        actions.resetForm();
      } else {
        toast.success("Form not submitted successfully!");
      }

      // Show success toast

      // You can also reset the form if needed
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!");
    }

    actions.setSubmitting(false);
  };

  return (
    <div className="container ">
      <div className="row d-flex justify-content-center">
        <div className="col-md-5">
          <div className="edit_input_userimg_div_main">
            {/* Image Preview */}

            {profilePreview ? (
              <img
                src={profilePreview}
                alt="Profile"
                style={{
                  height: 105,
                  width: 105,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <LuUser2 style={{ fontSize: "105px" }} />
            )}

            <div className="upload_div_main">
              <div className="input_usr_updd">
                <input
                  type="file"
                  name="profile"
                  id="imageInput"
                  accept="image/*"
                  onChange={editChange}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="imageInput"
                  className="uploadLabel"
                  style={{ cursor: "pointer" }}
                >
                  <IoCamera style={{ fontSize: "30px" }} />
                </label>
              </div>
            </div>
          </div>
          <Toaster />
          <div className="bg-warning">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="pt-3 form_main_div">
                    <div className="row d-flex justify-content-between">
                      <div className="col-md-12 mt-1">
                        <div className="form_input_div">
                          <label>
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            className="Form_Field"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mt-1">
                        <div className="form_input_div">
                          <label>
                            Email <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            className="Form_Field"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mt-1">
                        <div className="form_input_div">
                          <label>
                            Mobile <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            type="text"
                            name="mobile"
                            placeholder="Enter your phone number"
                            maxLength={10}
                            className="Form_Field"
                            readOnly
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="common_button bg-white "
                          disabled={isSubmitting}
                        >
                          Edit Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
