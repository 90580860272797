const menuData = [
  {
    title: "Water Purifiers",
    items: [
      { name: "RO Water Purifier", path: "/waterpurifier" },
      { name: "Water Filter", path: "/waterpurifier" },
      { name: "UV Water Purifier", path: "/waterpurifier" },
      { name: "Gravity Water Purifier", path: "/waterpurifier" },
      { name: "Commercial Water Purifier", path: "/waterpurifier" },
    ],
  },
  {
    title: "Water Dispensers",
    items: [
      { name: "Hot & Normal", path: "/waterdispensers" },
      { name: "Hot, Normal & Cold", path: "/waterdispensers" },
      { name: "Hot, Normal, Cold with RO", path: "/waterdispensers" },
      { name: "Hot, Normal, Cold & Cooling Cabinet", path: "/waterdispensers" },
    ],
  },
  {
    title: "Our Shops",
    items: [
      { name: "Aquafresh", path: "https://aquafreshwaterpurifiers.in/" },
      { name: "Grand Plus", path: "https://grandpluswaterpurifier.com/" },
      { name: "Royal Aquafresh", path: "https://royalaquafreshwaterpurifier.com/" },
      { name: "Keel", path: "https://keelwaterpurifier.com/" },
    ],
  },
  // {
  //   title: "Kitchen Appliances",
  //   items: [
  //     { name: "Mixer Grinder", path: "/kitchenappliances" },
  //     { name: "Egg Boiler", path: "/kitchenappliances" },
  //   ],
  // },
  // {
  //   title: "Vacuum Cleaners",
  //   items: [
  //     { name: "Wet & Dry Vacuum Cleaner", path: "/vacuumcleaner" },
  //     {
  //       name: "Wiz Handheld Vacuum Cleaner",
  //       path: "/vacuumcleaner",
  //     },
  //   ],
  // },
];

export default menuData;
