import React, { useRef, useState } from "react";
import "../../styles/Footer.css";

const footerContent = [
  {
    id: 1,
    header: "Products",
    links: [
      {
        href: "/waterpurifier",
        iconClass: "bx bx-droplet",
        text: "Water Purifier",
      },
      {
        href: "/waterdispensers",
        iconClass: "bx bx-droplet",
        text: "Water Dispensers",
      },
      {
        href: "/kitchenappliances",
        iconClass: "bx bx-droplet",
        text: "Kitchen Appliances (Launching Soon)",
      },
      {
        href: "/vacuumcleaner",
        iconClass: "bx bx-droplet",
        text: "Vacuum Cleaner (Launching Soon)",
      },
    ],
  },
  {
    id: 2,
    header: "Our Brands",
    links: [
      {
        href: "https://aquafreshwaterpurifiers.in/",
        iconClass: "bx bx-droplet",
        text: "Aquafresh",
      },
      {
        href: "https://grandpluswaterpurifier.com/",
        iconClass: "bx bx-droplet",
        text: "Grand Plus",
      },
      {
        href: "https://royalaquafreshwaterpurifier.com/",
        iconClass: "bx bx-droplet",
        text: "Royal Aquafresh",
      },
      {
        href: "https://keelwaterpurifier.com/",
        iconClass: "bx bx-droplet",
        text: "Keel",
      },
    ],
  },
  {
    id: 3,
    header: "Useful Links",
    links: [
      { href: "/aboutus", iconClass: "bx bx-droplet", text: "About Us" },
      { href: "/contactus", iconClass: "bx bx-droplet", text: "Contact Us" },
      {
        href: "/termsconditions",
        iconClass: "bx bx-droplet",
        text: "Terms & Conditions",
      },
      {
        href: "/returnrefundpolicy",
        iconClass: "bx bx-droplet",
        text: "Return & Refund Policy",
      },
      {
        href: "/privacypolicy",
        iconClass: "bx bx-droplet",
        text: "Privacy Policy",
      },
      { href: "/disclaimer", iconClass: "bx bx-droplet", text: "Disclaimer" },
    ],
  },
];

const AccordionItem = ({ item, handleToggle, active }) => {
  const contentEl = useRef();
  const { header, id, links } = item;

  return (
    <div className="footer-accordion-card">
      <div className="footer-accordion-header">
        <div
          className={`footer-accordion-toggle p-3 ${
            active === id ? "active" : ""
          }`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="footer-accordion-title">{header}</h5>
          <i className="fa-regular fa-plus footer-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`footer-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="footer-accordion-body">
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {links.map((link, index) => (
              <li key={index}>
                <a href={link.href}>
                  <i className={link.iconClass} />
                  <span>{link.text}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const FooterAccordion = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (id) => {
    setActive(active === id ? null : id);
  };

  return (
    <div className="container mt-3 mb-2">
      <div className="row justify-content-center">
        <div className="col-md-12 p-0">
          {footerContent.map((item) => (
            <AccordionItem
              key={item.id}
              item={item}
              active={active}
              handleToggle={handleToggle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterAccordion;
