import React, { useEffect, useState } from "react";
import "../../styles/Header.css";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa6";
import { getDecryptedCookie } from "../../utils/Utils";
import { GetUserProfile } from "../../api/UsersApi";
import { Sidenavbar } from "./Sidenavbar";
import logo from "../../assets/images/logo/logo2.png";
import { MdLogout } from "react-icons/md";
import Cookies from "js-cookie";
import menuData from "./MenuData";
import { SearchProductApi } from "../../api/ProductsApi";
import { Api_Url } from "../../constants/Url";

export const Header = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value) {
      try {
        const response = await SearchProductApi({ productName: value });
        console.log(response, "response search data");
        setSearchResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click was outside of the dropdown and input field
      if (!event.target.closest(".search-container")) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    Cookies.remove("UserData");
    window.location.href = "/";
  };

  const userProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile; // Use mobile instead of userId

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      // Call GetUserProfile API with mobile and token
      const res = await GetUserProfile(mobile, token);

      if (res?.status === "success") {
        setUserData(res.data); // Store the fetched user profile data in the state
      } else {
        console.error("Failed to fetch user profile", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userProfile(); // Fetch the user profile when the component mounts
  }, []);

  return (
    <>
      {/* main div header wrapper start */}
      <div className="header_main_wraper_div">
        {/* top section start */}
        <section className="top_bar_section">
          <div className="container-fluid">
            <div className="row">
              {/* col start */}
              <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="topbar_left_div_txt">
                  {/* <Link to="/service">Customer Service</Link> */}
                  {/* <Link to="">Where To Buy</Link> */}
                  <Link to="/aboutus">About Us</Link>
                  {/* <Link to="">Become a KSA</Link> */}
                </div>
              </div>
              {/* col end */}

              {/* col start */}
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="topbar_center_div_txt">
                  {/* <Link to="">
                    <i className="bx bx-location-plus bx-tada"></i> Enter your
                    location pin code
                  </Link> */}
                </div>
              </div>
              {/* col end */}

              {/* col start */}
              <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                <div className="topbar_right_div_txt">
                  {/* <Link to="tel:+919810392397">
                    <i className="bx bx-phone bx-tada"></i> +91-9810392397
                  </Link> */}
                  <Link to="mailto:helpdesk@balajiwaterpurifiers.in">
                    <i className=" bx bx-envelope bx-tada"></i>
                    <span className="text-white">
                      {" "}
                      helpdesk@balajiwaterpurifiers.in
                    </span>
                  </Link>
                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </section>
        {/* top section end */}

        {/* navbar section start */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={logo} className="img-fluid" alt="" />
            </Link>
            {/* phone view icons s */}

            <div className="phone_view_icons_div">
              <span className="phone_icons_nav">
                {/* <Link to="/login" className="bx bxs-user-circle"></Link> */}
                <Link to="/cart" className="bx bx-cart  position-relative">
                  <span className="badge position-absolute top-1 start-100 translate-middle">
                    {userData?.items?.length}
                  </span>
                </Link>
              </span>
              <Link
                to="#sidenavbar"
                className="offcanvas_btn bx bx-menu-alt-right"
                data-bs-toggle="offcanvas"
                aria-controls="offcanvasScrolling"
              ></Link>
            </div>

            <div className="mobile-view">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="w-100"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  placeholder="Search for products"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                />

                {/* Dropdown menu */}
                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/waterpurifier/productdetails/${product._id}`
                            )
                          }
                        >
                          <div>
                            <div>
                              <div>{product.ProductName}</div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              </div>
                            </div>

                            <div className="text-success">
                              {`₹ ${parseInt(
                                product?.totalPriceWithTax
                              ).toLocaleString()}`}{" "}
                              /-
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">No products found</div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* end */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>

                {menuData?.map((menu, index) => (
                  <li key={index} className="nav-item dropdown__item">
                    <div className="nav-link">
                      {menu.title} <FaChevronDown className="dropdown__arrow" />
                    </div>
                    <ul className="dropdown__menu">
                      {menu.items.map((item, idx) => (
                        <li key={idx}>
                          {menu.title === "Our Shops" ? (
                            // Open in new tab for "Our Shops" items
                            <a
                              href={item.path}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown__link"
                            >
                              {item.name}
                            </a>
                          ) : (
                            // Open in the same tab for other items
                            <Link to={item.path} className="dropdown__link">
                              {item.name}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}

                <li className="nav-item">
                  <Link className="nav-link" to="/contactus">
                    Contact Us
                  </Link>
                </li>
              </ul>
              {/* navbar search div */}

              <div
                className="search-container"
                style={{ position: "relative" }}
              >
                <input
                  type="text"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  placeholder="Search for products"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                />

                {/* Dropdown menu */}
                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/waterpurifier/productdetails/${product._id}`
                            )
                          }
                        >
                          <div>
                            <div>
                              <div>{product.ProductName}</div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              </div>
                            </div>

                            <div className="text-success">
                              {`₹ ${parseInt(
                                product?.totalPriceWithTax
                              ).toLocaleString()}`}{" "}
                              /-
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">No products found</div>
                    )}
                  </div>
                )}
              </div>

              {/* navbar search div */}

              <ul className="navbar_user_cart">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={userData?.mobile ? "/userprofile" : "/login"} // Conditionally navigate based on login status
                  >
                    {userData?.mobile ? (
                      <div className="d-flex align-items-center">
                        <div>
                          {userData?.ProfilePhoto ? (
                            <div className="userProfilePhoto">
                              <img
                                src={`${Api_Url}${userData?.ProfilePhoto}`}
                                alt=""
                                className="userProfilePhoto"
                              />
                            </div>
                          ) : (
                            <div>
                              <span className="bx bx-user-circle"></span>
                            </div>
                          )}
                        </div>

                        <span className="ps-1" style={{ fontSize: "12px" }}>
                          {userData?.name && userData?.mobile
                            ? userData?.name
                            : userData?.mobile}
                        </span>

                        <div
                          className="d-flex ps-3 align-items-center cursor-pointer"
                          onClick={handleLogout}
                        >
                          <span>
                            <MdLogout />
                          </span>
                          <span> Logout</span>
                        </div>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </Link>
                </li>
                <li className="nav-item ps-2">
                  <Link className="nav-link position-relative" to="/cart">
                    <span className="bx bx-cart"></span> Cart
                    <span className="badge position-absolute top-0 start-10 translate-middle text-white">
                      {userData?.items?.length}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* end navr section */}
      </div>
      {/* main div header wrapper end */}

      {/* sidenavbar start */}
      <Sidenavbar />
      {/* sidenavbar end */}
    </>
  );
};
