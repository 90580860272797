import { Api_Url } from "../constants/Url";

export const FetchAllBanners = async () => {
    try {
      const res = await fetch(`${Api_Url}/websitebanners/getallbanners`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      });
  
      return await res.json();
    } catch (error) {
      console.log("An error occurred:", error);
      return {
        success: false,
        message: "An error occurred while fetching the Banners",
      };
    }
  };
  