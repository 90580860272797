import React, { useEffect, useState } from "react";
import { BlogData } from "../../data/BlogsData";
import image1 from "../../assets/images/image/off1.jpg";
import Slider from "react-slick";

export const LatestBlogs = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="testimonial_section">
        <div className="container">
          <div className="testimonails_title">
            <h2>Latest Blogs</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className="choose_products_slider_main_div">
                {BlogData ? (
                  <div className="row ">
                    <Slider {...settings}>
                      {BlogData?.map((ele, index) => {
                        return (
                          <>
                            <div
                              className="item"
                              key={index}
                              data-aos="fade-up"
                            >
                              <div className="p-1">
                                <div className="card_style_1">
                                  <div className="w-100">
                                    <img
                                      src={image1}
                                      alt={ele?.image}
                                      className="img-fluid w-100"
                                      style={{
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <div className="content" style={{minHeight:"120px"}}>
                                    <h5 className="py-1 m-0">{ele?.title}</h5>
                                    <p>{ele?.description}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="loader"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
