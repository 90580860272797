import React from "react";
import image1 from "../assets/images/image/off1.jpg";
import image2 from "../assets/images/image/off2.jpg";
import Slider from "react-slick";

export const AdsBanner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="testimonial_section">
        <div className="container desktop-view">
          <div className="row px-2">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <img
                src={image1}
                alt=""
                className="w-100"
                style={{ borderRadius: "10px" }}
              ></img>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <img
                src={image2}
                alt=""
                className="w-100"
                style={{ borderRadius: "10px" }}
              ></img>
            </div>
          </div>
        </div>

        <div className="container mobile-view">
          <div className="row">
            <Slider {...settings}>
              <div className="col-12 p-0">
                <img
                  src={image1}
                  alt=""
                  className="w-100"
                  style={{ borderRadius: "10px" }}
                ></img>
              </div>
              <div className="col-12 p-0">
                <img
                  src={image2}
                  alt=""
                  className="w-100"
                  style={{ borderRadius: "10px" }}
                ></img>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};
