import React from "react";
import "../styles/Payment.css";

export const PaymentSuccess = () => {
  return (
    <div>
      <div
        style={{ height: "80vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div>
          <div
            className="swal2-icon swal2-success swal2-animate-success-icon"
            style={{ display: "flex" }}
          >
            <div
              className="swal2-success-circular-line-left"
              style={{ backgroundColor: "rgb(255, 255, 255)" }}
            ></div>
            <span className="swal2-success-line-tip"></span>
            <span className="swal2-success-line-long"></span>
            <div className="swal2-success-ring"></div>
            <div
              className="swal2-success-fix"
              style={{ backgroundColor: "rgb(255, 255, 255)" }}
            ></div>
            <div
              className="swal2-success-circular-line-right"
              style={{ backgroundColor: "rgb(255, 255, 255)" }}
            ></div>
          </div>

          <div className="d-flex justify-content-center">
            <h3 className="text-gray-600 my-2">
              Thank you for completing your secure online payment.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
