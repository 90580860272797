import React from "react";
import { Navigate } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils";

// This component restricts the Login route for authenticated users
export const AuthRoute = ({ children }) => {
  const userData = getDecryptedCookie("UserData"); 
  const isAuthenticated = userData?.token;

  return isAuthenticated ? <Navigate to="/" /> : children;
};