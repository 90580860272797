export const BlogData = [
  {
    title: "The Importance of Clean Drinking Water",
    description:
      "Clean drinking water is essential for health and wellness. In this blog, we explore the benefits of purified water and why it's necessary that necessary can lead to serious health issues. Discover how water purifiers can help ensure your water is safe for drinking.",
    image: "/images/clean_drinking_water.jpg",
  },
  {
    title: "How to Choose the Right Water Purifier",
    description:
      "With so many water purifiers on the market, it can be tough to find the right one for your needs. This guide breaks down the types of purifiers, from RO and UV to activated carbon filters, and helps you choose the best option based on your water and family size.",
    image: "/images/choose_water_purifier.jpg",
  },
  {
    title: "Top 5 Benefits of Using a Water Purifier",
    description:
      "Water purifiers do more than just clean your water. They offer various health and lifestyle benefits, such as improving taste, reducing harmful chemicals, and lowering the risk of waterborne diseases. Read on to learn about the of having a water purifier at home.",
    image: "/images/benefits_of_purifier.jpg",
  },
  // {
  //   title: "Understanding Reverse Osmosis (RO) Water Purification",
  //   description:
  //     "Reverse osmosis is one of the most popular methods for water purification, but how does it work? This article explains the RO process, its advantages, and why it’s an effective way to remove impurities, salts, and harmful bacteria from your drinking water.",
  //   image: "/images/ro_purification.jpg",
  // },
  // {
  //   title: "Common Myths About Water Purifiers Debunked",
  //   description:
  //     "There are many misconceptions about water purifiers, like they remove essential minerals or aren’t necessary with municipal water. In this blog, we debunk common myths and provide insights into why water purifiers are beneficial for every household.",
  //   image: "/images/water_purifier_myths.jpg",
  // },
];
