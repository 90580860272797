import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { FetchAllBanners } from "../../api/WebsiteBanners";
import { Api_Url } from "../../constants/Url";

export const Banners = () => {
  const [DesktopBanners, SetDesktopBanners] = useState();
  const [MobileBanners, SetMobileBanners] = useState();

  const banners = async () => {
    try {
      const response = await FetchAllBanners();
      if (response?.status === "success") {
        SetDesktopBanners(response?.data[0]?.DesktopBanners);
        SetMobileBanners(response?.data[0]?.MobileBanners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    banners();
  }, []);

  const DesktopBannersSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <div className="desktop-view">
        {DesktopBanners?.length > 0 ? (
          <Slider {...DesktopBannersSettings}>
            {DesktopBanners?.length > 0 ? (
              DesktopBanners.map((image, index) => (
                <div key={index} className="img_box_pro_dt">
                  <img
                    src={`${Api_Url}${image}`} // Ensure the path is correct
                    alt=""
                    className="img-fluid"
                  />
                </div>
              ))
            ) : (
              <div>No banners available</div>
            )}
          </Slider>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        )}
      </div>


      <div className="mobile-view">
        {MobileBanners?.length > 0 ? (
          <Slider {...DesktopBannersSettings}>
            {MobileBanners?.length > 0 ? (
              MobileBanners.map((image, index) => (
                <div key={index} className="img_box_pro_dt">
                  <img
                    src={`${Api_Url}${image}`} // Ensure the path is correct
                    alt=""
                    className="img-fluid"
                  />
                </div>
              ))
            ) : (
              <div>No banners available</div>
            )}
          </Slider>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};
