import React from "react";
import { Link } from "react-router-dom";
import "../styles/Disclaimer.css";

export const ReturnRefundPolicy = () => {
  return (
    <div>
      <section className="inner_page_tisection_bred">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  -
                  <li>
                    <Link to="/">Return & Refund Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="termsconditions_section">
        <div className="container">
          <div className="termsconditions_title_div">
            <h1>Return & Refund Policy</h1>
            <p>
              <strong>Last updated: September 28, 2024</strong>
            </p>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="termsconditions_txt_div">
              <p>Thank you for shopping at Balaji Traders.</p>
              <p>
                If, for any reason, You are not completely satisfied with a
                purchase, we invite You to review our policy on refunds and
                returns. The following terms are applicable for any products
                that You purchased with Us.
              </p>

              <h5>Interpretation and Definitions</h5>
              <h6>Interpretation</h6>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or plural.
              </p>

              <h6>Definitions</h6>
              <p>For the purposes of this Return and Refund Policy:</p>
              <ul>
                <li>
                  <strong>Company</strong> (referred to as either "the Company",
                  "We", "Us" or "Our" in this Agreement) refers to Balaji
                  Traders.
                </li>
                <li>
                  <strong>Goods</strong> refer to the items offered for sale on
                  the Service.
                </li>
                <li>
                  <strong>Orders</strong> mean a request by You to purchase
                  Goods from Us.
                </li>
                <li>
                  <strong>Service</strong> refers to the Website.
                </li>
                <li>
                  <strong>Website</strong> refers to Balaji Traders, accessible
                  from{" "}
                  <a href="https://www.balajiwaterpurifier.com/">
                    https://www.balajiwaterpurifier.com/
                  </a>
                </li>
                <li>
                  <strong>You</strong> means the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                </li>
              </ul>

              <h5>Your Order Cancellation Rights</h5>
              <p>
                You are entitled to cancel Your Order within 7 days without
                giving any reason for doing so. The deadline for cancelling an
                Order is 7 days from the date on which You received the Goods or
                on which a third party you have appointed, who is not the
                carrier, takes possession of the product delivered.
              </p>
              <p>
                In order to exercise Your right of cancellation, You must inform
                Us of your decision by means of a clear statement. You can
                inform us of your decision by:
              </p>
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:helpdesk@balajiwaterpurifier.com">
                    helpdesk@balajiwaterpurifier.com
                  </a>
                </li>
              </ul>
              <p>
                We will reimburse You no later than 14 days from the day on
                which We receive the returned Goods. We will use the same means
                of payment as You used for the Order, and You will not incur any
                fees for such reimbursement.
              </p>

              <h5>Conditions for Returns</h5>
              <p>
                In order for the Goods to be eligible for a return, please make
                sure that:
              </p>
              <ul>
                <li>The Goods were purchased in the last 7 days</li>
                <li>The Goods are in the original packaging</li>
              </ul>
              <p>The following Goods cannot be returned:</p>
              <ul>
                <li>
                  The supply of Goods made to Your specifications or clearly
                  personalized.
                </li>
                <li>
                  The supply of Goods which according to their nature are not
                  suitable to be returned, deteriorate rapidly or where the date
                  of expiry is over.
                </li>
                <li>
                  The supply of Goods which are not suitable for return due to
                  health protection or hygiene reasons and were unsealed after
                  delivery.
                </li>
                <li>
                  The supply of Goods which are, after delivery, according to
                  their nature, inseparably mixed with other items.
                </li>
              </ul>
              <p>
                We reserve the right to refuse returns of any merchandise that
                does not meet the above return conditions in our sole
                discretion.
              </p>
              <p>
                Only regular priced Goods may be refunded. Unfortunately, Goods
                on sale cannot be refunded. This exclusion may not apply to You
                if it is not permitted by applicable law.
              </p>

              <h5>Returning Goods</h5>
              <p>
                You are responsible for the cost and risk of returning the Goods
                to Us. You should send the Goods to the following address:
              </p>
              <p>
                Plot No - 70, Vikash Vihar Near SS Property, Rohini Sec - 22,
                Delhi - 110086 (INDIA)
              </p>
              <p>
                We cannot be held responsible for Goods damaged or lost in
                return shipment. Therefore, We recommend an insured and
                trackable mail service. We are unable to issue a refund without
                actual receipt of the Goods or proof of received return
                delivery.
              </p>

              <h5>Gifts</h5>
              <p>
                If the Goods were marked as a gift when purchased and then
                shipped directly to you, You'll receive a gift credit for the
                value of your return. Once the returned product is received, a
                gift certificate will be mailed to You.
              </p>
              <p>
                If the Goods weren't marked as a gift when purchased, or the
                gift giver had the Order shipped to themselves to give it to You
                later, We will send the refund to the gift giver.
              </p>

              <h5>Contact Us</h5>
              <p>
                If you have any questions about our Returns and Refunds Policy,
                please contact us:
              </p>
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:helpdesk@balajiwaterpurifier.com">
                    helpdesk@balajiwaterpurifier.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
