import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

const SECRET_KEY = "";

export const encryptData = (data) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    return encrypted;
  } catch (error) {
    return null;
    // eff
  }
};

export const setEncryptedCookie = (key, data) => {
  // huhuhuhu
  try {
    const encryptedData = encryptData(data);
    if (encryptedData) {
      Cookies.set(key, encryptedData, { expires: 7 }); // Cookie expires in 7 days
    } else {
    }
  } catch (error) {}
};

export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted;
  } catch (error) {
    return null;
  }
};

export const getDecryptedCookie = (key) => {
  try {
    const encryptedData = Cookies.get(key);
    if (encryptedData) {
      return decryptData(encryptedData);
    }
    return null;
  } catch (error) {
    return null;
  }
};
