import "./App.css";
import "../src/styles/Home.css";
import "../src/styles/Loader.css";
import "../src/styles/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

import { HelmetProvider } from "react-helmet-async";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { DashBoard } from "./pages/DashBoard";
import { Login } from "./pages/Login";
import { PaymentSuccess } from "./pages/PaymentSuccess";
import { AboutUs } from "./pages/AboutUs";
import { CheckOut } from "./pages/CheckOut";
import { ContactUs } from "./pages/ContactUs";
import { Cart } from "./pages/Cart";
import { SiteMap } from "./pages/SiteMap";
import { NotFound } from "./pages/NotFound";
import { TermsConditions } from "./pages/TermsConditions";
import { ReturnRefundPolicy } from "./pages/ReturnRefundPolicy";
import { Disclaimer } from "./pages/Disclaimer";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ProductDetails } from "./pages/ProductDetails";
import { UserProfile } from "./pages/UserProfile";
import { EditProfile } from "./pages/EditProfile";
import { EditAddress } from "./pages/EditAddress";
import { PrivateRoute } from "./constants/PrivateRoute";
import { WaterPurifier } from "./pages/WaterPurifier";
import { VacuumCleaner } from "./pages/VacuumCleaner";
import { WaterDispensers } from "./pages/WaterDispensers";
import { KitchenAppliances } from "./pages/KitchenAppliances";
import { Service } from "./pages/Service";
import AOS from "aos";
import "aos/dist/aos.css";
import { AuthRoute } from "./constants/AuthRoute";

function App() {
  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="/" element={<DashBoard />} />
              <Route
                path="/login"
                element={
                  <AuthRoute>
                    <Login />
                  </AuthRoute>
                }
              />

              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/sitemap" element={<SiteMap />} />

              <Route
                path="/checkout"
                element={
                  <PrivateRoute>
                    <CheckOut />
                  </PrivateRoute>
                }
              />

              {/* Water Purifier */}

              <Route path="/waterpurifier" element={<WaterPurifier />} />
              <Route
                path="/waterpurifier/productdetails/:productId"
                element={<ProductDetails />}
              />
              <Route
                path="/waterpurifier/productdetails/:productId/checkout"
                element={
                  <PrivateRoute>
                    <CheckOut />
                  </PrivateRoute>
                }
              />

              {/* Vacuum Cleaner */}

              <Route path="/vacuumcleaner" element={<VacuumCleaner />} />
              <Route
                path="/vacuumcleaner/productdetails/:productId"
                element={<ProductDetails />}
              />
              <Route
                path="/vacuumcleaner/productdetails/:productId/checkout"
                element={
                  <PrivateRoute>
                    <CheckOut />
                  </PrivateRoute>
                }
              />

              {/* Kitchen Appliances */}

              <Route
                path="/kitchenappliances"
                element={<KitchenAppliances />}
              />
              <Route
                path="/kitchenappliances/productdetails/:productId"
                element={<ProductDetails />}
              />
              <Route
                path="/kitchenappliances/productdetails/:productId/checkout"
                element={
                  <PrivateRoute>
                    <CheckOut />
                  </PrivateRoute>
                }
              />

              {/* Water Dispensers */}

              <Route path="/waterdispensers" element={<WaterDispensers />} />
              <Route
                path="/waterdispensers/productdetails/:productId"
                element={
                  <PrivateRoute>
                    <ProductDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/waterdispensers/productdetails/:productId/checkout"
                element={<CheckOut />}
              />

              <Route path="/service" element={<Service />} />

              <Route path="/paymentsuccess" element={<PaymentSuccess />} />

              <Route path="/termsconditions" element={<TermsConditions />} />
              <Route
                path="/returnrefundpolicy"
                element={<ReturnRefundPolicy />}
              />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />

              {/* Wrap protected routes inside PrivateRoute */}
              <Route
                path="/userprofile"
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                }
              />

              <Route
                path="/editprofile"
                element={
                  <PrivateRoute>
                    <EditProfile />
                  </PrivateRoute>
                }
              />

              <Route
                path="/editaddress"
                element={
                  <PrivateRoute>
                    <EditAddress />
                  </PrivateRoute>
                }
              />

              <Route
                path="/cart"
                element={
                  <PrivateRoute>
                    <Cart />
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
