import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddToCart, FetchAllProducts } from "../../api/ProductsApi";
import { getDecryptedCookie } from "../../utils/Utils";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineShoppingCart } from "react-icons/md";
import "../../styles/BestSellingProducts.css";
import { Api_Url } from "../../constants/Url";

const NewArrivals = () => {
  const navigate = useNavigate();

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const [AllProductsData, setAllProductsData] = useState();

  const AllProducts = async () => {
    try {
      const res = await FetchAllProducts();
      if (res?.status === "success") {
        setAllProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllProducts();
  }, []);

  const HandleAddtoCart = async (productData) => {
    try {
      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(`waterpurifier/productdetails/${productId}`);
  };

  return (
    <div>
      <Toaster />
      <section className="testimonial_section desktop-view">
        <div className="container">
          <div className="testimonails_title">
            <h2>New Arrivals</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="testimonial_main_div">
                {AllProductsData ? (
                  <div className="d-flex" style={{ overflow: "auto" }}>
                    {AllProductsData?.slice(0, 4)?.map((ele) => {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-12"
                          key={ele?._id}
                          data-aos="zoom-in"
                        >
                          <div
                            className="best_selling_product_main_div"
                            onClick={() => handleViewProduct(ele?._id)}
                          >
                            <div className="best_selling_img_box">
                              <img
                                src={`${Api_Url}${ele?.ProductImage?.[0]}`}
                                alt={ele?.ProductName}
                                className="img-fluid"
                              />
                            </div>

                            <div className="selling_products_name_div">
                              <h2 className="m-0">{ele?.ProductName}</h2>
                              <p>
                                <b>
                                  {`₹ ${parseInt(
                                    ele?.priceAfterDiscount
                                  ).toLocaleString()}`}{" "}
                                  /-
                                </b>
                                <span className="ps-2">
                                  {`₹ ${parseInt(
                                    ele?.totalPriceWithTax
                                  ).toLocaleString()}`}{" "}
                                  /-
                                </span>
                                <b className="ps-2 text-success">
                                  Save {ele?.discountPercentage}%
                                </b>
                              </p>
                            </div>

                            <div className="product_Details">
                              <div className="view_Product_Details">
                                <div>View Product</div>
                              </div>

                              {/* Add to Cart button with stopPropagation */}
                              <div
                                className="add_to_cart bg-white"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent navigation
                                  HandleAddtoCart(ele); // Calls the Add to Cart API
                                }}
                              >
                                <MdOutlineShoppingCart
                                  style={{ fontSize: "20px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="loader"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial_section mobile-view">
        <div className="container">
          <div className="testimonails_title">
            <h2>New Arrivals</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="testimonial_main_div">
                {AllProductsData ? (
                  <div className="d-flex" style={{ overflow: "auto" }}>
                    {AllProductsData?.slice(0, 4)?.map((ele) => {
                      return (
                        <div
                          className="col-6"
                          key={ele?._id}
                          data-aos="zoom-in"
                        >
                          <div
                            className="best_selling_product_main_div"
                            onClick={() => handleViewProduct(ele?._id)}
                          >
                            <div className="best_selling_img_box">
                              <img
                                src={`${Api_Url}${ele?.ProductImage?.[0]}`}
                                alt={ele?.ProductName}
                                className="img-fluid"
                              />
                            </div>

                            <div className="selling_products_name_div">
                              <h2 className="m-0">{ele?.ProductName}</h2>
                              <p>
                                <b>
                                  {`₹ ${parseInt(
                                    ele?.priceAfterDiscount
                                  ).toLocaleString()}`}{" "}
                                  /-
                                </b>
                                <span className="ps-2">
                                  {`₹ ${parseInt(
                                    ele?.totalPriceWithTax
                                  ).toLocaleString()}`}{" "}
                                  /-
                                </span>
                                <b className="ps-2 text-success">
                                  Save {ele?.discountPercentage}%
                                </b>
                              </p>
                            </div>

                            <div className="product_Details">
                              <div className="view_Product_Details">
                                <div style={{ fontSize: "12px" }}>View</div>
                              </div>

                              {/* Add to Cart button with stopPropagation */}
                              <div
                                className="add_to_cart bg-white"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent navigation
                                  HandleAddtoCart(ele); // Calls the Add to Cart API
                                }}
                              >
                                <MdOutlineShoppingCart
                                  style={{ fontSize: "12px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="loader"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewArrivals;
