import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../styles/Disclaimer.css";

export const Disclaimer = () => {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
        <title>Pre </title>

        <meta
          name="description"
          content="We know being physically fit not only impacts the personality but also improves brain health, concentration and work efficiency.
for mor info visit us our website : https://www.balajiwaterpurifier.com/ & Call Now@+91-8826479778."
        />

        <meta name="keywords" content="Water purifier" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="robots" content="follow, index, all" />
        <meta name="googlebot" content="index,follow" />
        <meta name="author" content="https://www.balajiwaterpurifier.com/" />
        <meta name="language" content="English" />
        <meta name="document-type" content="public" />
        <meta name="country" content="India" />
        <meta name="state" content="Delhi" />
        <meta name="city" content="Uttam Nagar" />
        <link rel="canonical" href="https://www.balajiwaterpurifier.com/" />
        <meta
          name="google-site-verification"
          content="m0KZx3MYSbGbUmI0FedSoD5eZI6dZxsQkiMqs70fabI"
        />
      </Helmet>

      <section className="inner_page_tisection_bred">
        <div className="container-fluid">
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  -
                  <li>
                    <Link to="/"> Disclaimer</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/*Page Title Area End */}

      <section className="termsconditions_section">
        <div className="container">
          <div className="termsconditions_title_div">
            <h1>Disclaimer</h1>
          </div>

          {/* col start */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="termsconditions_txt_div">
              <h5>Introduction</h5>
              <p>
                Welcome to Balaji Water Purifier, where we provide insightful
                astrology readings, consultations, and reports to help
                individuals navigate their lives with clarity and purpose. We
                are committed to delivering accurate and personalized astrology
                services to our valued customers. As part of our commitment to
                transparency and customer satisfaction, we have outlined our
                refund and cancellation policy below.
              </p>

              <h5>Refund Policy</h5>
              <p>
                At Balaji Water Purifier, we prioritize the quality and accuracy of
                our astrology services. Due to the nature of our personalized
                astrology readings, consultations, and reports, we do not offer
                refunds once a service is purchased. Our team of experienced
                astrologers invests time and effort into crafting each reading
                and report tailored to the individual's unique astrological
                profile. Therefore, all sales are final, and refunds will not be
                issued. The amount will be refunded in 7 working days from the
                day of the concern receipt.
              </p>

              <h5>Contact Us</h5>
              <p>
                If you have any questions, concerns, or inquiries regarding our
                refund and cancellation policy or any other aspect of our
                astrology services, please don't hesitate to contact us. You can
                reach our customer support team at contact@healfate.com, and we
                will be happy to assist you.
              </p>

              <p>
                <strong>Last Updated: November 1, 2024</strong>
              </p>
            </div>
          </div>
          {/* col end */}
        </div>
      </section>
    </div>
  );
};
